import dashboard from './dashboard';
import pages from './pages';
import admins from './admins';
import orders from './orders';
import users from './users';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [dashboard, orders, users]
};

export default menuItems;
