// assets
import { IconTypography, IconPalette, IconShadow, IconWindmill } from '@tabler/icons';

// constant
const icons = {
    IconTypography,
    IconPalette,
    IconShadow,
    IconWindmill
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const orders = {
    id: 'orders',
    title: 'Orders',
    type: 'group',
    children: [
        {
            id: 'order features',
            title: 'Order Features',
            type: 'collapse',
            icon: icons.IconKey,
            children: [
                {
                    id: 'view orders',
                    title: 'View Orders',
                    type: 'item',
                    url: '/ordersPanel',
                    icon: icons.IconTypography,
                    breadcrumbs: false
                },
                {
                    id: 'payment logs',
                    title: 'PaymentLogs',
                    type: 'item',
                    url: '/paymentsLog',
                    icon: icons.IconTypography,
                    breadcrumbs: false
                }
                // {
                //     id: 'util-shadow',
                //     title: 'Shadow',
                //     type: 'item',
                //     url: '/utils/util-shadow',
                //     icon: icons.IconShadow,
                //     breadcrumbs: false
                // }
                // // {
                //     id: 'icons',
                //     title: 'Icons',
                //     type: 'collapse',
                //     icon: icons.IconWindmill,
                //     children: [
                //         {
                //             id: 'tabler-icons',
                //             title: 'Tabler Icons',
                //             type: 'item',
                //             url: '/icons/tabler-icons',
                //             breadcrumbs: false
                //         },
                //         {
                //             id: 'material-icons',
                //             title: 'Material Icons',
                //             type: 'item',
                //             url: '/icons/material-icons',
                //             breadcrumbs: false
                //         }
                //     ]
                // }
            ]
        }
    ]
};

export default orders;
