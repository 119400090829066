import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';
import Protectedroutes from './protectedroutes';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// utilities routing
const Typography = Loadable(lazy(() => import('views/utilities/Typography')));
const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));
const AuthLogin3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Login3')));
const UsersTable = Loadable(lazy(() => import('views/utilities/userTable')));
const OrdersPanel = Loadable(lazy(() => import('views/utilities/ordersPanel')));
const PaymentsLogs = Loadable(lazy(() => import('views/utilities/paymentLogs')));

// sample page routing
const SamplePage = Loadable(lazy(() => import('views/sample-page')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <Protectedroutes component={<MainLayout />} />,
    children: [
        {
            path: '/',
            element: <Protectedroutes component={<DashboardDefault />} />
        },
        {
            path: 'dashboard',
            children: [
                {
                    path: 'default',
                    element: <Protectedroutes component={<DashboardDefault />} />
                }
            ]
        },
        {
            //path: 'utils',
            children: [
                {
                    path: 'util-typography',
                    element: <Protectedroutes component={<Typography />} />
                }
            ]
        },
        {
            //path: 'utils',
            children: [
                {
                    path: 'userTable',
                    element: <Protectedroutes component={<UsersTable />} />
                }
            ]
        },
        {
            //path: 'utils',
            children: [
                {
                    path: 'ordersPanel',
                    element: <Protectedroutes component={<OrdersPanel />} />
                }
            ]
        },
        {
            //path: 'utils',
            children: [
                {
                    path: 'paymentsLog',
                    element: <Protectedroutes component={<PaymentsLogs />} />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-color',
                    element: <Protectedroutes component={<UtilsColor />} />
                }
            ]
        },
        {
            path: 'utils',
            children: [
                {
                    path: 'util-shadow',
                    element: <Protectedroutes component={<UtilsShadow />} />
                }
            ]
        },
        {
            path: 'icons',
            children: [
                {
                    path: 'tabler-icons',
                    element: <Protectedroutes component={<UtilsTablerIcons />} />
                }
            ]
        },
        {
            path: 'icons',
            children: [
                {
                    path: 'material-icons',
                    element: <Protectedroutes component={<UtilsMaterialIcons />} />
                }
            ]
        },
        {
            path: 'sample-page',
            element: <Protectedroutes component={<SamplePage />} />
        }
    ]
};

export default MainRoutes;
